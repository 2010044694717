@mixin custom-line-chart() {
  .ngx-charts-tooltip-content.type-tooltip {
    background-color: var(--bc-background) !important;
    border-radius: 4px;
    border: 1px solid var(--text-300);
    color: var(--text-500);
  }

  .ngx-charts-tooltip-content.type-tooltip .tooltip-caret.position-top {
    border-top-color: var(--text-300) !important;
  }

  .ngx-charts-tooltip-content.type-tooltip .tooltip-caret.position-right {
    border-right-color: var(--text-300) !important;
  }

  .ngx-charts-tooltip-content.type-tooltip .tooltip-caret.position-bottom {
    border-bottom-color: var(--text-300) !important;
  }

  .ngx-charts-tooltip-content.type-tooltip .tooltip-caret.position-left {
    border-left-color: var(--text-300) !important;
  }

  .ngx-charts .tooltip-anchor {
    fill: none !important;
  }

  .ngx-charts .tooltip-bar {
    fill: none;
  }

  .ngx-charts .x.axis .gridline-path-vertical {
    stroke: var(--text-500);
  }

  .ngx-charts text {
    fill: var(--text-500) !important;
    font-size: 13px !important;
  }

  .primary-line-chart {
    .ngx-charts .line-series .line {
      stroke-width: 2px;
      stroke: var(--CTA-500);
    }

    .ngx-charts .line-chart .line-highlight {
      display: block !important;
    }

    .ngx-charts circle {
      fill: var(--text-500);
    }
  }

  .ngx-charts linearGradient stop {
    stop-color: var(--CTA-500) !important;
  }

  .chart-legend .legend-labels {
    background: none !important;
    max-height: fit-content !important;
  }
}