@mixin custom-overlay() {
  .app-overlay {
    background: rgba(255, 255, 255, 0.5);
  }

  .mat-progress-bar-fill::after {
    background-color: var(--CTA-500);
  }
  .mat-progress-bar {
    border-radius: 10px !important;
  }
}

@mixin modal-overlay() {
  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
  }
}
