@mixin mapbox() {
  .mapboxgl-map {
    font: 16px 'DM Sans' !important;
  }
  .hide-mapbox-popup-content {
    visibility: hidden;
  }
  .mapboxgl-control-container {
    height: auto !important;
    width: auto !important;
    .mapboxgl-ctrl {
      margin: 0 8px 10px 0 !important;
      button {
        height: 34px !important;
        width: 34px !important;
      }
    }
  }
  .mapboxgl-ctrl-attrib {
    display: none;
  }
  .mapboxgl-popup-tip {
    display: none;
  }
  .mapboxgl-popup {
    .mapboxgl-popup-content {
      background: transparent;
      display: flex;
      position: absolute;
      align-items: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.8125rem;
      line-height: 0.86125rem;
      white-space: nowrap;
      .icon {
        height: 1.3em;
        margin-right: 0.4rem;
      }
      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &.white .mapboxgl-popup-content,
    &.large-white .mapboxgl-popup-content {
      background-color: var(--bc-background);
      border: 1px solid var(--text-500);
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      color: var(--text-500);
    }

    &.large-white .mapboxgl-popup-content {
      padding: 0.5rem;
      border-radius: 0.375rem;
    }

    &.trail .mapboxgl-popup-content {
      background-color: var(--bc-background);
      border: 0.125rem solid var(--Gray-Nutral-Gray-500);
      color: var(--text-500);
      padding: 0.5rem;
      border-radius: 0.375rem;
    }

    &.hidden .mapboxgl-popup-content {
      display: none;
    }

    &.unselected .mapboxgl-popup-content {
      display: none;
    }

    &.red .mapboxgl-popup-content {
      border-radius: 0.125rem;
      background-color: var(--dv-red);
      padding: 0.1875rem 0.5rem;
      color: var(--text-white);
      font-weight: 700;
    }

    &.gray .mapboxgl-popup-content {
      border-radius: 0.125rem;
      background-color: rgba(0, 0, 0, 0.38);
      padding: 0.1875rem 0.5rem;
      color: var(--text-white);
      font-weight: 700;
    }

    &.hidden-polygon-label {
      visibility: hidden;
    }
  }
  app-ship-profile-tooltip {
    visibility: visible;
  }
}
