@mixin typography() {
  h1 {
    font-weight: 700;
    font-size: clamp(2rem, 3vw, 4rem);
    line-height: 54.06px;
  }

  h2 {
    font-weight: 700;
    font-size: clamp(1.75rem, 1.875vw, 3.75rem);
    line-height: 1;
    &.regular {
      font-weight: 400;
    }
  }

  h3 {
    font-weight: 700;
    font-size: clamp(1.5rem, 1.25vw, 2.75rem);
    line-height: 26.5px;
  }

  h4 {
    font-weight: 700;
    font-size: clamp(1.35rem, 1.15vw, 2rem);
    line-height: 22.26px;
    &.regular {
      font-weight: 400;
    }
  }

  h5 {
    font-weight: 400;
    font-size: clamp(1.25rem, 1.35vw, 1.8rem);
    line-height: 26.5px;
  }

  .subtitle1 {
    font-weight: 400;
    font-size: clamp(1rem, 0.8vw, 1.5rem);
    line-height: 18px;
  }

  .subtitle1-bold {
    font-weight: 700;
    font-size: clamp(1rem, 0.8vw, 1.5rem);
    line-height: 18px;
  }

  .subtitle2 {
    font-weight: 500;
    font-size: clamp(0.95rem, 0.75vw, 1.5rem);
    line-height: 15px;
  }

  .subtitle3 {
    font-weight: 400;
    font-size: clamp(1.35rem, 1.15vw, 2rem);
    line-height: 22.26px;
  }

  .body1 {
    font-weight: 400;
    font-size: clamp(0.95rem, 0.9vw, 1.2rem);
    line-height: 18px;
  }

  .body1-bold {
    font-weight: 700;
    font-size: clamp(0.95rem, 0.9vw, 1.2rem);
    line-height: 18px;
  }

  .button-text {
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
  }

  .caption {
    font-weight: 400;
    font-size: clamp(0.5rem, 0.7vw, 1rem);
    line-height: 14px;
  }
  .caption-bold {
    font-weight: 700;
    font-size: clamp(0.5rem, 0.7vw, 1rem);
    line-height: 14px;
  }
}
