@mixin custom-checkbox() {
  mat-checkbox.primary-outline {
    .mat-checkbox-frame {
      border-color: var(--text-500);
    }
  }
  .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
  .mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: var(--CTA-500);
  }
}
