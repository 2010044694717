@mixin custom-tabs() {
  .mat-tab-label-active {
    color: var(--text-400);
    font-weight: 700;
  }

  .explore-event-tab-group {
    .mat-tab-label {
      opacity: 1 !important;

      .mat-tab-label-content {
        span {
          opacity: 0.6 !important;
        }
      }
    }
  }
  .custom-tabs {
    .mat-tab-labels {
      background-color: var(--text-600);
    }
  }

  a {
    color: var(--CoolGray-90);
  }

  .mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs
    .mdc-tab-indicator--active {
    color: var(--new-Primary-Primary-500);
    font-weight: 600;
  }

  .mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
    display: flex;
    height: 3rem;
    padding: 1rem 0.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex-grow: 0 !important;
  }

  .mat-mdc-tab-links {
    gap: 1.5rem;
  }
}
