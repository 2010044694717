@import 'general';

@mixin custom-rt-samples-legend() {
  .sample {
    @include pie-chart-legend();

    .dot {
      @include sample-dot(var(--dv-dark-blue), var(--dv-purple), var(--dv-dark-blue));
    }
  }
}
