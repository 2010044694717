@mixin custom-slider {
  .custom-slider {
    z-index: 0;
    .ngx-slider .ngx-slider-pointer {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      top: -6px;
      background-color: var(--CTA-500);
    }
    .ngx-slider .ngx-slider-pointer:after {
      display: none;
    }

    .ngx-slider .ngx-slider-selection {
      background: var(--text-500);
    }
  }
}
