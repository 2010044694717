@mixin custom-buttons() {
  button.primary-text.mat-flat-button.mat-primary.mat-button-disabled {
    .mat-button-wrapper {
      color: var(--warn);
    }
  }
  button.mat-stroked-button.primary-border.primary-border {
    border-color: var(--CTA-500);
  }
}
