@mixin custom-scrollbar() {
  
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    margin: 4px 0;
  }
  ::-webkit-scrollbar-thumb {
    background: #97979C;
    border-radius: 10px;
  }
}
