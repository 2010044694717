@mixin dot() {
  height: clamp(10px, 0.52vw, 20px);
  width: clamp(10px, 0.52vw, 20px);
  border-radius: 3px;
}

@mixin alert-dot($high-color, $medium-color, $low-color) {
  @include dot();

  &.high {
    background-color: var(--graph-#{$high-color});
  }

  &.medium {
    background-color: var(--graph-#{$medium-color});
  }

  &.low {
    background-color: var(--graph-#{$low-color});
  }
}

@mixin severity-dot(
  $high-color: red,
  $medium-color: dark-orange,
  $low-color: light-orange
) {
  @include alert-dot($high-color, $medium-color, $low-color);

  &.all {
    background-color: var(--text-500);
  }
}

@mixin ships-dot($excellent-color, $good-color, $moderate-color, $low-color) {
  @include dot();
  &.excellent {
    background-color: var(--graph-#{$excellent-color});
  }
  &.good {
    background-color: var(--graph-#{$good-color});
  }
  &.moderate {
    background-color: var(--graph-#{$moderate-color});
  }
  &.low {
    background-color: var(--graph-#{$low-color});
  }
}

@mixin ships-severity-dot(
  $excellent-color: green,
  $good-color: darkseagreen,
  $moderate-color: yellow,
  $low-color: red
) {
  @include ships-dot(
    $excellent-color,
    $good-color,
    $moderate-color,
    $low-color
  );

  &.all {
    background-color: var(--text-500);
  }
}

@mixin sample-dot(
  $pitch-color: dark-blue,
  $roll-color: purple,
  $sog-color: dark-blue
) {
  @include dot();

  &.pitch {
    background-color: $pitch-color;
  }

  &.roll {
    background-color: $roll-color;
  }

  &.sog {
    background-color: $sog-color;
  }
}

@mixin general-classes() {
  @include simple-dot();

  :root {
    @include general-vars();
  }
}

@mixin general-vars() {
  --top-bar-min-height: 80px;
  --top-bar-max-height: 120px;
  --top-bar-optimal-height: 7vh;

  --top-bar-height: clamp(
    var(--top-bar-min-height),
    var(--top-bar-optimal-height),
    var(--top-bar-max-height)
  );

  --side-nav-min-width-open: 250px;
  --side-nav-optimal-width-open: 13vw;
  --side-nav-max-width-open: 350px;

  --side-nav-min-width-closed: 80px;
  --side-nav-optimal-width-closed: 4vw;
  --side-nav-max-width-closed: 120px;

  --main-app-gap: 20px;

  --side-nav-width-closed: clamp(
    var(--side-nav-min-width-closed),
    var(--side-nav-optimal-width-closed),
    var(--side-nav-max-width-closed)
  );
  --side-nav-width-open: clamp(
    var(--side-nav-min-width-open),
    var(--side-nav-optimal-width-open),
    var(--side-nav-max-width-open)
  );

  --overview-row-height: min(26vh, 280px);
  --overview-min-row-height: 280px;

  --overview-max-map-size: calc(
    100vh - var(--top-bar-height) - (3 * var(--main-app-gap)) -
      max(var(--overview-min-row-height), var(--overview-row-height))
  );
  --overview-map-height: max(300px, var(--overview-max-map-size));

  --padding-overview-events: 8px;
  --overview-events-min-height: calc(
    var(--overview-max-map-size) - var(--padding-overview-events)
  );

  --events-min-height: 350px;
  --events-score-by-date-height: 300px;
  --events-map-height: calc(
    100% - var(--events-score-by-date-height) - var(--main-app-gap)
  );
  --events-page-height: max(
    var(--events-min-height),
    calc(100vh - var(--top-bar-height) - (2 * var(--main-app-gap)))
  );

  --fleet-min-height: 350px;
  --fleet-page-height: max(
    var(--fleet-min-height),
    calc(100vh - var(--top-bar-height) - (2 * var(--main-app-gap)))
  );

  --subfleet-min-height: 350px;
  --subfleet-page-height: max(
    var(--subfleet-min-height),
    calc(100vh - var(--top-bar-height) - (2 * var(--main-app-gap)))
  );
  --side-nav-level: 998;

  --exploration-gap: 20px;
}

// dont include those in scss files as those are global classes
@mixin simple-dot() {
  .simple-dot {
    width: clamp(6px, 0.3125vw, 15px);
    height: clamp(6px, 0.3125vw, 15px);
    border-radius: 50%;
    background-color: var(--text-500);
  }
}

@mixin pie-chart-legend() {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 5px;
}
