@import 'general';

@mixin custom-ships-risks() {
  .risk {
    @include pie-chart-legend();

    .dot {
      @include ships-dot(green, darkseagreen, yellow, red);
    }
  }
  .severity {
    @include pie-chart-legend();
    .dot {
      @include severity-dot();
    }
  }

  .event-types {
    @include pie-chart-legend();

    .dot {
      @include dot();

      &.dangerous-rot {
        background-color: #64a7d8;
      }

      &.speed-drop {
        background-color: #28659e;
      }

      &.grounding-risk {
        background-color: #b276ff;
      }

      &.dangerous-motion {
        background-color: #789797;
      }

      &.collision-risk {
        background-color: #9b6fa9;
      }
    }
  }
}
