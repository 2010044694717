@mixin custom-date-picker() {
  .mat-datepicker-content {
    background-color: var(--bc-background);
    border: 1px solid var(--text-500);
    border-radius: 6px;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  .mat-datepicker-toggle {
    color: var(--text-500);
  }

  // Not working on firefox
  ::placeholder {
    color: var(--text-500);
  }

  .mat-calendar-header button,
  .mat-calendar-table span,
  .mat-calendar-table td,
  .mat-calendar-body-cell-content {
    color: var(--text-500);
  }

  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar-previous-button {
    color: var(--text-500);
  }

  .mat-calendar-arrow {
    fill: var(--text-500);
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-date-range-input-start-wrapper {
    overflow: visible !important;
  }

  .mat-calendar-body-in-range::before {
    background-color: var(--CTA-200);
  }

  .mat-calendar-body-selected {
    background-color: var(--CTA-500);
    color: var(--bc-background);
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: var(--text-500) !important;
    background-color: var(--bc-background) !important;
    border-color: rgba(215, 215, 215, 1) !important;
  }

  .mat-calendar-body-today.mat-calendar-body-selected {
    background-color: var(--CTA-500);
    color: var(--bc-background);
    border: double;
    box-shadow: none;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover {
    &>.mat-calendar-body-cell-content {
      &:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        color: var(--bc-background);
        background-color: var(--text-200);
      }
    }
  }

  .mat-datepicker-actions:not(.mat-primary) {
    color: var(--text-200);
  }
}