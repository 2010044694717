@mixin custom-tabs() {
  .mat-tab-label-active {
    color: var(--text-400);
    font-weight: 700;
  }

  .explore-event-tab-group {
    .mat-tab-label {
      opacity: 1 !important;

      .mat-tab-label-content {
        span {
          opacity: 0.6 !important;
        }
      }
    }
  }
  .custom-tabs {
    .mat-tab-labels {
      background-color: var(--text-600);
    }
  }
}
