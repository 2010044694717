@mixin custom-button-toggle() {
  .mat-button-toggle-group {
    font-size: 0.9375rem;
    font-weight: 700;
    border: 1px solid var(--CTA-500);
    border-radius: 6px;
    height: 36px;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    color: var(--CTA-500);
    line-height: 36px !important;
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard
    .mat-button-toggle-label-content {
    background-color: var(--CTA-500);
    color: var(--text-white);
  }

  .mat-button-toggle-group-appearance-standard
    .mat-button-toggle
    + .mat-button-toggle {
    border-left: 1px solid var(--CTA-500);
  }

  .cameras-selector {
    .mat-button-toggle-group {
      border: 1px solid var(--text-300);
    }
    .mat-button-toggle-checked.mat-button-toggle-appearance-standard
      .mat-button-toggle-label-content {
      background-color: var(--text-link);
    }
    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
      color: var(--text-300);
      font-weight: 400;
    }
    .mat-button-toggle-checked.mat-button-toggle-appearance-standard
      .mat-button-toggle-label-content {
      color: var(--text-white);
      font-weight: 700;
    }
    .mat-button-toggle-group-appearance-standard
      .mat-button-toggle
      + .mat-button-toggle {
      border-left: 1px solid var(--text-300);
    }
  }
}
