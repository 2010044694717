@use '@angular/material' as mat;
@import 'material-variables';

$dark-primary-text: #14105b;
$light-primary-text: #fff;

$primary: (
  50: #ebeef9,
  100: #cdd6f1,
  200: #acbae8,
  300: #8b9edf,
  400: #728ad8,
  500: #5975d1,
  600: #516dcc,
  700: #4862c6,
  800: #3e58c0,
  900: #2e45b5,
  A100: #f8f9ff,
  A200: #c5ceff,
  A400: #92a3ff,
  A700: #798eff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

$secondary: (
  50: #fce6ea,
  100: #f8c2cb,
  200: #f399a9,
  300: #ee7086,
  400: #eb516c,
  500: #e73252,
  600: #e42d4b,
  700: #e02641,
  800: #dd1f38,
  900: #d71328,
  A100: #ffffff,
  A200: #ffd3d7,
  A400: #ffa0a8,
  A700: #ff8691,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);
$warn: (
  50: #fce6ea,
  100: #f8c2cb,
  200: #f399a9,
  300: #ee7086,
  400: #eb516c,
  500: #e73252,
  600: #e42d4b,
  700: #e02641,
  800: #dd1f38,
  900: #d71328,
  A100: #ffffff,
  A200: #ffd3d7,
  A400: #ffa0a8,
  A700: #ff8691,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);
$kepler-plt: (
  green: #29a656,
  blue: #596cdb,
  red: #ff3e5f,
);
$container-plt: (
  error: #ffd3d5,
  orange-warning: #ffd9bd,
  yellow-warning: #ffedbf,
  green: #accc91,
  light-green: #d9eec8,
  contrast: (
    error: #a80513,
    orange-warning: #a43120,
    yellow-warning: #14105b,
    green: #285214,
    light-green: #285214,
  ),
);

$text-plt: (
  50: #e3e2eb,
  100: #b9b7ce,
  200: #8a88ad,
  300: #5b588c,
  500: #14105b,
  550: #2b2864,
  600: #f6f9fe,
  700: #8f98bc,
  white: #ffffff,
  link: #1d46cf,
  gray: #e3e3e3,
  bordeaux: #a80513,
);
$light-orange-plt: (
  900: #ef7e42,
  800: #f28f54,
  700: #f3985e,
  600: #f5a269,
  500: #f6a971,
  400: #f7b686,
  300: #f9c39c,
  200: #fbd4b8,
  100: #fce5d4,
  50: #fef5ee,
);

$yellow-shades-plt: (
  900: #ff9f08,
  800: #ffac10,
  700: #ffb414,
  600: #ffbc18,
  500: #ffc21b,
  400: #ffcb3d,
  300: #ffd45f,
  200: #ffe18d,
  100: #ffedbb,
  50: #fff8e4,
);

$blue-plt: (
  500: #14105b,
);

$purple-plt: (
  20: #d9d9d9,
  50: #ecf0fa,
  500: #647fd8,
);

$purple-shades-plt: (
  500: #625b71,
);

$avatar-plt: (
  100: #337810,
  110: #486969,
  120: #367cbf,
  130: #0f3770,
  140: #8a46ff,
  150: #6d407e,
  160: #d71328,
  170: #ef7e42,
  180: #06052f,
  190: #ff9f08,
  200: #625b71,
  210: #245d96,
  220: #61a42b,
  230: #5c9fd4,
  240: #ffc21b,
  250: #9b6fa9,
  260: #f6a971,
  270: #708f8f,
  280: #ab6eff,
  290: #eb516c,
);

$graphics-plt: (
  red: #d8183a,
  red-light: #f399a9,
  dark-orange: #dc5d00,
  light-orange: #f6a971,
  yellow: #ffc21b,
  green: #61a42b,
  darkseagreen: #b0d295,
  light-green: #85eeb9,
  soft-blue: #829eed,
  dark-blue: #005694,
  gray: #9c9c9c,
  pink: #ffd3d5,
);
$data-visualization-plt: (
  red: #e73252,
  light-blue: #64a7d8,
  soft-blue: #8a88ad,
  dark-blue: #28659e,
  purple: #b276ff,
);
$backgrounds-plt: (
  light-blue: #edf2ff,
  light-blue2: #F3F7FF,
  background: #f6f9fe,
  light: #f9fafb,
  surface: #ffffff,
  disabled: #f5f5f5,
  blue: #2f60eb,
);
$CTA-plt: (
  500: #5975d1,
  200: #dce1ff,
  100: #d1d9f3,
  50: #cbc8d3,
  contrast: (
    500: #ffffff,
    200: #14105b,
    100: #ffffff,
    50: #14105b,
  ),
);

@mixin light-theme() {
  $light-plt-primary: mat.define-palette($primary, 500);
  $light-plt-secondary: mat.define-palette($secondary, 500);
  $light-plt-warn: mat.define-palette($warn, 500);
  $light-typography: mat.define-legacy-typography-config(
    $font-family: 'DM Sans',
  );
  $light-theme: mat-light-theme(
    (
      color: (
        primary: $light-plt-primary,
        secondary: $light-plt-secondary,
        accent: $light-plt-secondary,
        warn: $light-plt-warn,
      ),
      typography: $light-typography,
    )
  );

  @include mat.all-legacy-component-themes($light-theme);

  :root {
    @include material-variables(
      $warn,
      $container-plt,
      $text-plt,
      $yellow-shades-plt,
      $blue-plt,
      $purple-plt,
      $purple-shades-plt,
      $light-orange-plt,
      $avatar-plt,
      $graphics-plt,
      $data-visualization-plt,
      $backgrounds-plt,
      $CTA-plt,
      $kepler-plt,
      $light-theme
    );
  }
}
