@mixin custom-radio-buttons() {
  .time-radio-button .mat-radio-outer-circle {
    border-color: var(--CTA-500);
  }

  .time-radio-button.mat-radio-button.mat-accent.mat-radio-checked
    .mat-radio-outer-circle {
    border-color: var(--CTA-500);
  }

  .time-radio-button.mat-radio-button.mat-accent
    .mat-radio-ripple
    .mat-ripple-element {
    opacity: 0;
  }

  .time-radio-button.mat-radio-button.mat-accent
    .mat-radio-ripple
    .mat-ripple-element:not(.mat-radio-persistent-ripple) {
    background-color: rgba(0, 0, 0, 0);
  }

  .time-radio-button.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: var(--CTA-500);
  }
}
