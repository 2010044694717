@mixin custom-form-fields() {
  mat-form-field.primary-outline {
    .mat-form-field-outline {
      color: var(--text-500);
    }

    .mat-form-field-label {
      color: var(--text-200);
    }

    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
      color: var(--text-500);
    }

    .mat-form-field-suffix {
      top: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0px;
    }
  }

  .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
    color: var(--text-500);
  }

  .search-form .mat-form-field-flex>.mat-form-field-infix {
    padding: 0.4em 0px !important;
  }

  .search-form .mat-form-field-label-wrapper {
    top: -1.5em;
  }

  .search-form .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.1em) scale(.75);
    width: 133.33333%;
  }

  mat-form-field.gray-outline {
    .mat-form-field-outline {
      color: var(--text-500);
    }

    .mat-form-field-label {
      color: var(--text-500);
    }

    .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
      color: var(--text-500);
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
      padding-top: 10px;
    }
  }

  mat-form-field.custom-dates {
    mat-label {
      color: var(--text-500);
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
      padding-top: 10px;

      .mat-form-field-flex {
        padding: 0;
      }
    }
  }

  .mdc-floating-label--float-above {
    transform: translateY(-2rem) scale(0.75) !important;
  }

  .mat-mdc-form-field-infix {
    align-self: center !important;
  }

  mat-form-field.custom-form-field-selection {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-flex {
      padding: 0 10px;
      background-color: rgba(0, 0, 0, 0);
    }

    .mat-form-field-label {
      color: var(--text-500);
      top: 1.34375em;
    }

    .mat-select-arrow-wrapper {
      transform: translateY(0%);

      .mat-select-arrow {
        color: var(--text-500);
      }
    }
  }

  mat-form-field.custom-events-form-field-selection {
    .mat-select-value {
      width: auto;
    }
    .mat-select-trigger {
      width: auto;
    }
    .mat-form-field-infix {
      width: auto;
    }
    .mat-form-field-flex {
      padding: 0px;
      margin-bottom: 0.25rem;
    }
  }

  mat-form-field.custom-fuel-events-form-field-selection {
    .mat-select-value {
      width: auto;
      max-width: 3.2rem;
    }
    .mat-select-trigger {
      width: auto;
    }
    .mat-form-field-infix {
      width: auto;
    }
    .mat-form-field-flex {
      padding: 0px;
      margin-bottom: 0.25rem;
    }
  }
}
