@mixin custom-expansion {
  .mat-expansion-panel.mat-expanded .mat-expansion-panel-header-title .icon {
    transform: rotate(0deg);
  }

  .mat-expansion-panel .mat-expansion-panel-header-title .icon {
    transform: rotate(-90deg);
  }

  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none !important;
  }

  .mat-expansion-panel {
    background: var(--bc-surface) !important;
    color: var(--text-500) !important;

    .mat-expansion-panel-header-title {
      color: var(--text-500) !important;
    }
  }

  .mat-expansion-panel-header[aria-disabled='true']
    .mat-expansion-panel-header-title,
  .mat-expansion-panel-header[aria-disabled='true']
    .mat-expansion-panel-header-description {
    color: var(--text-500) !important;
  }

  @media (hover: none) {
    .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true'])
      .mat-expansion-panel-header:hover {
      background-color: var(--bc-surface) !important;
    }
  }

  .custom-expansion {
    .mat-expansion-panel-header {
      padding: 0 30px;
    }
    .mat-expansion-panel-body {
      padding: 10px 35px 16px;
    }

    &.no-padding {
      .mat-expansion-panel-body {
        padding: 0px;
      }
    }
  }
}
