@mixin custom-dialog() {
  .reports-dialog-container {
    .mat-dialog-container {
      background: var(--bc-light);
      border-radius: 0.5rem;
      width: 46.875rem;
      height: 37.25rem;
    }
  }
}
