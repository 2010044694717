@import 'custom-theme';
@import 'typography';
@import 'custom-overlay';
@import 'custom-snackbar';
@import 'custom-form-fields';
@import 'custom-checkbox';
@import 'custom-buttons';
@import 'custom-line-chart';
@import 'custom-ships-risks';
@import 'custom-rt-samples-legend';
@import 'custom-menus';
@import 'custom-date-picker';
@import 'custom-radio-buttons';
@import 'general';
@import 'mapbox';
@import 'custom-expansion';
@import 'custom-slider';
@import 'custom-select';
@import 'custom-button-toggle';
@import 'custom-tree';
@import 'custom-tabs';
@import 'custom-scrollbar';
@import 'custom-progress-bar';
@import 'custom-spinner';
@import 'custom-dialog';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');

@include typography();
@include custom-theme();
@include custom-overlay();
@include custom-snackbar();
@include custom-form-fields();
@include custom-checkbox();
@include custom-buttons();
@include custom-line-chart();
@include custom-ships-risks();
@include custom-rt-samples-legend();
@include custom-menus();
@include custom-radio-buttons();
@include custom-expansion();
@include custom-date-picker();
@include custom-slider();
@include general-classes();
@include custom-select();
@include custom-button-toggle();
@include mapbox();
@include custom-tree();
@include custom-tabs();
@include custom-scrollbar();
@include custom-progress-bar();
@include custom-spinner();
@include custom-dialog();

html,
body {
  --Primary-Primary-100: #edf2ff;
  --Primary-Primary-500: #2f60eb;
  --Gray-Nutral-Gray-50: #f9fafb;
  --Gray-Nutral-Gray-200: #e4e7ec;
  --Gray-Nutral-Gray-300: #d0d5dd;
  --Gray-Nutral-Gray-400: #98a2b3;
  --Gray-Nutral-Gray-500: #667085;
  --Gray-Nutral-Gray-700: #344054;
  --Info-Info-100: #edf8ff;
  --Info-Info-600: #0a7dcf;
  --Info-Info-700: #005694;
  --Warning-warning-100: #fffbed;
  --Warning-Warning-600: #b2910d;
  --Error-Error-100: #ffeeed;
  --Error-Error-600: #cf170a;
  --black-white-white: #fff;
  --Graph-colors-Graph-01: #85eeb9;
  --Graph-colors-Graph-02: #829eed;

  width: 100%;
  height: 100%;
  font-family: 'DM Sans', sans-serif;
  background: var(--bc-background);
}

body {
  display: grid;
  user-select: none;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
