@mixin custom-menus() {
  .mat-menu-panel {
    background-color: var(--bc-background);
    .mat-menu-item {
      color: var(--text-500);
      .mat-icon-no-color {
        color: var(--text-500);
      }
    }

    button.mat-menu-item {
      color: var(--text-500);
      display: flex;
      align-items: center;
      gap: 18px;
    }

    .mat-menu-content {
      border: 1px solid var(--text-500);
      border-radius: 6px;
    }
  }

  .time-menu.mat-menu-panel {
    max-width: 320px;
    color: var(--text-500);
  }

  .time-menu.mat-menu-panel .mat-menu-content {
    padding: 0px;
  }

  .time-menu.mat-menu-panel .mat-menu-content .mat-radio-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
  }

  .time-menu.mat-menu-panel
    .mat-menu-content
    .mat-radio-group
    .mat-radio-button {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    height: 30px;
  }

  .time-menu.mat-menu-panel
    .mat-menu-content
    .mat-radio-group
    .mat-radio-button
    .mat-radio-label
    .mat-radio-label-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
