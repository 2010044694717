@mixin custom-select() {
  .custom-select.mat-option.mat-active {
    color: var(--text-500);
  }

  .custom-select.mat-select-panel {
    background-color: var(--bc-surface);
  }

  .custom-select .search-container {
    padding: 1rem;
    border-bottom: 1px solid var(--divider);
    .search-input {
      width: 100%;
      color: var(--text-500);
    }
  }

  .mat-option {
    color: var(--text-500);
    .mat-pseudo-checkbox {
      border-color: var(--text-500);
    }
  }

  .mat-select-value {
    color: var(--text-500);
  }

  .mat-option.mat-active {
    color: var(--text-500);
  }

  .custom-select.mat-select-arrow-wrapper {
    color: var(--text-500);
  }

  .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
    color: var(--conc-error);
  }
  .mat-select-panel-wrap {
    .mat-select-panel .mat-optgroup-label,
    .mat-select-panel .mat-option,
    .search-container {
      height: 100%;
      background-color: var(--bc-background);
      gap: 15px;
    }
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: var(--text-500);
  }
}
