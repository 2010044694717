@use 'functions' as funcs;
@import '@angular/material/theming';

@mixin material-variables(
  $plt-warn,
  $plt-container,
  $plt-text,
  $plt-yellow-shades,
  $plt-blue,
  $plt-purple,
  $plt-purple-shades,
  $plt-light-orange,
  $plt-avatar,
  $plt-graphics,
  $plt-data-visualization,
  $plt-backgrounds,
  $plt-CTA,
  $plt-kepler,
  $theme
) {
  $warn-contrast: map-get($plt-warn, contrast);
  $warn-keys: (
    50,
    100,
    200,
    300,
    400,
    500,
    600,
    700,
    800,
    900,
    A100,
    A200,
    A400,
    A700
  );

  $plt-container-contrast: map-get($plt-container, contrast);
  $plt-CTA-contrast: map-get($plt-CTA, contrast);
  $plt-background: map-get($theme, background);
  $plt-foreground: map-get($theme, foreground);

  $con-keys: (error, orange-warning, yellow-warning, green, light-green);
  $text-keys: (50, 100, 200, 300, 500, 550, 600, 700, white, link, gray, bordeaux);
  $blue-keys: (500);
  $purple-keys: (20, 50, 500);
  $purple-shades-keys: (500);
  $light-orange-keys: (900, 800, 700, 600, 500, 400, 300, 200, 100, 50);
  $yellow-shades-keys: (900, 800, 700, 600, 500, 400, 300, 200, 100, 50);
  $avatar-keys: (
    100,
    110,
    120,
    130,
    140,
    150,
    160,
    170,
    180,
    190,
    200,
    210,
    220,
    230,
    240,
    250,
    260,
    270,
    280,
    290
  );
  $graph-keys: (
    red,
    red-light,
    dark-orange,
    light-orange,
    light-green,
    soft-blue,
    dark-blue,
    yellow,
    green,
    darkseagreen,
    gray,
    pink
  );
  $dv-keys: (purple, light-blue, dark-blue, red, soft-blue);
  $bg-keys: (background, surface, disabled, light, light-blue, light-blue2, blue);
  $CTA-keys: (500, 200, 100, 50);
  $kp-keys: (green, blue, red);

  @each $key in $warn-keys {
    --warn-#{$key}: #{map-get($plt-warn, $key)};
    --warnc-#{$key}: #{map-get($warn-contrast, $key)};
  }

  @each $key in $con-keys {
    --con-#{$key}: #{map-get($plt-container, $key)};
    --conc-#{$key}: #{map-get($plt-container-contrast, $key)};
  }

  @each $key in $text-keys {
    --text-#{$key}: #{map-get($plt-text, $key)};
  }

  @each $key in $yellow-shades-keys {
    --yellow-shades-#{$key}: #{map-get($plt-yellow-shades, $key)};
  }

  @each $key in $purple-keys {
    --purple-#{$key}: #{map-get($plt-purple, $key)};
  }

  @each $key in $purple-shades-keys {
    --purple-shades-#{$key}: #{map-get($plt-purple-shades, $key)};
  }

  @each $key in $light-orange-keys {
    --light-orange-#{$key}: #{map-get($plt-light-orange, $key)};
  }

  @each $key in $avatar-keys {
    --avatar-#{$key}: #{map-get($plt-avatar, $key)};
  }

  @each $key in $graph-keys {
    --graph-#{$key}: #{map-get($plt-graphics, $key)};
  }

  @each $key in $dv-keys {
    --dv-#{$key}: #{map-get($plt-data-visualization, $key)};
  }

  @each $key in $bg-keys {
    --bc-#{$key}: #{map-get($plt-backgrounds, $key)};
  }

  @each $key in $bg-keys {
    --bc-#{$key}-rgb: #{funcs.hex2rgb(map-get($plt-backgrounds, $key))};
  }

  @each $key in $CTA-keys {
    --CTA-#{$key}: #{map-get($plt-CTA, $key)};
    --CTAc-#{$key}: #{map-get($plt-CTA-contrast, $key)};
  }

  @each $key in $kp-keys {
    --kp#{$key}: #{map-get($plt-kepler, $key)};
  }

  --divider: #d7d7d7;
}
