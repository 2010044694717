@mixin custom-progress-bar() {
  .ship-action-progress-bar {
    .mat-progress-bar-fill::after {
      background-color: var(--graph-darkseagreen);
    }

    .mat-progress-bar-buffer {
      background-color: var(--graph-red-light);
    }
  }
  .mdc-linear-progress__bar {
    height: 100% !important;
  }
  .safety-events {
    .mdc-linear-progress__bar-inner {
      border-color: var(--Graf-colors-Graf-02, #829eed);
    }
    .mdc-linear-progress__bar {
      background-color: var(--Graf-colors-Graf-02, #829eed) !important;
    }
    background-color: var(--Gray-Nutral-Gray-200);
  }

  .acceleration {
    .mdc-linear-progress__bar-inner {
      border-color: var(--Info-Info-700, #005694);
    }
    .mdc-linear-progress__bar {
      background-color: var(--Info-Info-700, #005694) !important;
    }
    background-color: var(--Gray-Nutral-Gray-200);
  }

  mat-progress-bar {
    border-radius: 0.25rem !important;
  }
}
